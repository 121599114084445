// Generated by Framer (3fa6aa4)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./Mz_t6Xm8u-0.js";

const cycleOrder = ["ubtb2VBEj"];

const serializationHash = "framer-nDi7I"

const variantClassNames = {ubtb2VBEj: "framer-v-t044h3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ubtb2VBEj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t044h3", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ubtb2VBEj"} ref={ref ?? ref1} style={{backgroundColor: "rgb(191, 103, 103)", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nDi7I.framer-19e61at, .framer-nDi7I .framer-19e61at { display: block; }", ".framer-nDi7I.framer-t044h3 { height: 248px; overflow: visible; position: relative; width: 280px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 248
 * @framerIntrinsicWidth 280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMz_t6Xm8u: React.ComponentType<Props> = withCSS(Component, css, "framer-nDi7I") as typeof Component;
export default FramerMz_t6Xm8u;

FramerMz_t6Xm8u.displayName = "Prufukassi";

FramerMz_t6Xm8u.defaultProps = {height: 248, width: 280};

addFonts(FramerMz_t6Xm8u, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})